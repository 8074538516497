<template>
	<AppInput >
		<v-select
			:value="value"
			@input="e => $emit('input', e)"
			:items="items"
			:item-text="itemText"
			:item-value="itemValue"
			:label="label"
			:placeholder="placeholder"
			:disabled="disabled"
			:error-messages="errorLabel"
			:rules="rules"
			:row="row"
			:helper-text="helperMessage"
			:name="name"
			:outlined="outlined"
			:dense="dense"
			:hide-details="hideDetails"
		>
		
		</v-select>
	</AppInput>
  
</template>

<script >
import AppInput from "@/components/Forms/AppInput.vue";

export default {
    name: "AppSelect",
	components: {AppInput},
	props: {
		value: {
			required: true
		},
		items: {
			required: true
		},
		itemText: {},
		itemValue: {},
		label: {
			type: String
		},
		
		placeholder: {
			type: String,
		},
		disabled: {
			type: Boolean
		},
		errorLabel: {
			
		},
		rules: {
			
		},
		row: {
			type: Boolean
		},
		helperMessage: {},
		name: {
			type: String
		},
		outlined: {
			type: Boolean,
			default: true
		},
		dense: {
			type: Boolean,
			default: true
		},
		hideDetails: {
			type: Boolean,
			default: true
		},
	},

}
</script>

<style scoped>

</style>