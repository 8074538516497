const TASKBOOK_TASKS = 'TASKBOOK_TASKS'
const TASKBOOK = 'TASKBOOK'
const TASKBOOK_DAYS = 'TASKBOOK_DAYS'

const TASK_PAGE_LAYOUT = 'TASK_PAGE_LAYOUT'
const CURRENT_LOGO = 'CURRENT_LOGO'

export {
    TASKBOOK_TASKS,
    TASKBOOK,
    TASKBOOK_DAYS,
    TASK_PAGE_LAYOUT,
    CURRENT_LOGO
}