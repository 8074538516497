import moment from "moment";
import {frequencyTypes} from "./constants";
export default class Day {
    constructor(date, taskBook) {
        const {tasks} = taskBook;
        this.date = date;
        this.day = date.format("dddd");
        this.dayOfMonth = date.date();
        this.isWorkDay = moment(this.date).day() === 0 || moment(this.date).day() === 6;
        this.displayDate = date.format("MMMM D, yyyy");
        this.tasks = this.getDayTasks(taskBook);
        
    }

    getDayTasks(taskBook) {
        let self = this;
        let dayTasks = [];


        taskBook.tasks.forEach(task => {
            // don't include task if the task has a from date before this.date or a to date after this.date
            if (task.from && moment(this.date).isBefore(moment(task.from)) || task.to && moment(this.date).isAfter(moment(task.to))) {
                return;
            }
            
            
            switch (task.frequencyType) {
                case 1:
                    dayTasks.push({
                        label: task.label
                    })
                    break;
                case 2:
                    if (moment(this.date).day() !== 0 && moment(this.date).day() !== 6) {
                        dayTasks.push({
                            label:task.label
                        })
                    }
                    break;
                case 3:
                    // check if this.date is the same as task.from
                    if (moment(this.date).isSame(moment(task.from), 'day')) {
                        dayTasks.push({
                            label:task.label
                        })
                    }
                    break
                case 4:
                    // check if this.date is the same as task.on
                    if (moment(this.date).date() === task.on) {
                        dayTasks.push({
                            label:task.label
                        })
                    }
                    break;
                case 5:
                    // check if this.date is the first instance of the month
                    if (moment(this.date).day() === task.on && moment(this.date).date() <= 7) {
                        dayTasks.push({
                            label:task.label
                        })
                    }
                    break;
                case 6:
                    // check if this.date is the second instance of the month
                    if (moment(this.date).day() === task.on && moment(this.date).date() > 7 && moment(this.date).date() <= 14) {
                        dayTasks.push({
                            label:task.label
                        })
                    }
                    break;
                case 7:
                    // check if this.date is the third instance of the month
                    if (moment(this.date).day() === task.on && moment(this.date).date() > 14 && moment(this.date).date() <= 21) {
                        dayTasks.push({
                            label:task.label
                        })
                    }
                    break;
                case 8:
                    // check if this.date is the fourth instance of the month
                    if (moment(this.date).day() === task.on && moment(this.date).date() > 21 && moment(this.date).date() <= 28) {
                        dayTasks.push({
                            label:task.label
                        })
                    }
                    break;
                case 9:
                    // check if this.date is the last instance of the month
                    if (moment(this.date).day() === task.on && moment(this.date).date() > moment(this.date).daysInMonth() - 7 && moment(this.date).date() <= moment(this.date).daysInMonth()) {
                        dayTasks.push({
                            label:task.label
                        })
                    }
                    break;
                case 10:
                    // check if this.date is the last day of the month
                    if (moment(this.date).date() === moment(this.date).daysInMonth()) {
                        dayTasks.push({
                            label:task.label
                        })
                    }
                    break;
                case 11:
                    // check if this.date is a the day of the week
                    if (moment(this.date).day() === task.on) {
                        dayTasks.push({
                            label:task.label
                        })
                    }
                    break;
                case 12:
                    let difference = moment(this.date).diff(moment(task.from), 'weeks')
                    let isEveryOtherWeek = difference === 0 || difference % 2 === 0
                    console.log(difference, this.date.format('MM-DD-YYYY dddd'), isEveryOtherWeek)
                    if (task.from && isEveryOtherWeek && moment(this.date).day() === task.on) {
                        dayTasks.push({
                            label:task.label
                        })
                    } else if (!task.from && isEveryOtherWeek && moment(this.date).day() === task.on) {
                        dayTasks.push({
                            label:task.label
                        })
                    }
            }
                
        })
        

        

        for (let i = 0; dayTasks.length < taskBook.taskPageLayout.defaultTasksSectionLength; i++)
        {
            dayTasks.push({
                label: "",
            });
        }


        return dayTasks;
    }
}