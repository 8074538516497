<template>
  <div>
	  	<v-text-field
		  :value="value"
		  @input="e => $emit('input', e)"
		  :label="label"
		  :placeholder="placeholder"
		  :disabled="disabled"
		  :error-messages="errorLabel"
		  :rules="rules"
		  :row="row"
		  :helper-text="helperMessage"
		  :name="name"
		  outlined
		  dense
		  hide-details
		  v-on="on"
		  v-bind="bind"
	></v-text-field>
  </div>
</template>

<script >
export default {
	name: "AppTextField",
	props: {
		value: {
			required: true
		},
		label: {
			type: String
		},
		placeholder: {
			type: String,
		},
		disabled: {
			type: Boolean
		},
		errorLabel: {
			
		},
		rules: {
			
		},
		row: {
			type: Boolean
		},
		helperMessage: {},
		name: {
			type: String
		},
		on: {},
		bind: {}
	},
}
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
	border-color: #eeeeee;
}
</style>