<template>
	<div>
	
		<v-container >
			<AppBtn @click="print" class="mb-4">Print to PDF</AppBtn>
			<v-alert
				border="left"
				icon="mdi-printer-outline"
				type="info"
				
				text
			>
				Set your browser print settings to have <strong>no margins</strong>. Chrome is currently the best browser to use for this printing function. Please report any issues or bugs
				<a
					href="https://docs.google.com/forms/d/e/1FAIpQLSf5l2wMdXSyshcCUiAoDYr_WQ5-GUSdlWe6wT4EEzv-GtfMKA/viewform?usp=sf_link"
					target="_blank"
				>
					here</a>.
			</v-alert>
		</v-container>
		
		<div style="display: flex; justify-content: center">
			<div style="display: flex; justify-content: center; height: 80vh; width: 9in; overflow-y: scroll; padding: 10px; border: 1px solid #bdbdbd; background-color: white">
				<div id="print-area">
					<CoverPage :taskBook="TASKBOOK"></CoverPage>
					<div v-for="(day, index) in TASKBOOK_DAYS" :key="index">
						<Calendar
							v-if="day.dayOfMonth === 1 || index === 0"
							:start-date="day.date"
						></Calendar>
						<div :is="taskPage" :day="day" :taskBook="TASKBOOK"></div>
<!--						<TaskPage2 :day="day" :taskBook="TASKBOOK"></TaskPage2>-->
<!--						<PrintPage>-->
<!--							<div class="header">-->
<!--								<div class="day">{{day.day}}</div>-->
<!--								<div class="date">{{day.displayDate}}</div>-->
<!--							</div>-->
<!--							<TaskList :tasks="day.tasks"></TaskList>-->
<!--							<div style="display: flex; justify-content: center; padding-top: 10px; color: #bdbdbd">{{taskBook.info.motto}}</div>-->
<!--						</PrintPage>-->
					
					
					</div>
				</div>
			</div>
		</div>
	
	
	</div>
</template>

<script>
import TaskList from "@/components/TaskList.vue";
import PrintPage from "@/components/PrintPage.vue";
import CoverPage from "@/components/CoverPage/index.vue";
import Calendar from "@/components/Calendar/index.vue";
import moment from "moment/moment";
import Day from "@/js/Day";
import AppBtn from "@/components/AppBtn.vue";
import TaskPage1 from "@/components/TaskBookDocument/TaskPages/TaskPage1.vue";
import TaskPage2 from "@/components/TaskBookDocument/TaskPages/TaskPage2.vue";
import {mapGetters} from "vuex";
import {TASKBOOK, TASKBOOK_DAYS} from "@/store/getters";

export default {
    name: "TaskBookDocument",
	components: {TaskPage1, TaskPage2, AppBtn, Calendar, CoverPage, PrintPage, TaskList},
	props: {
		// taskBook: {
		// 	required: true
		// }
	},
	methods: {
		print() {
			
			this.$emit('print')
		}
	},
	computed: {
		// days() {
		// 	const {TASKBOOK} = this;
		// 	if (!TASKBOOK) return
		// 	let days = [];
		// 	let date = moment(TASKBOOK.info.startDate).clone();
		// 	for (let i = 0; date.isSameOrBefore(TASKBOOK.info.endDate); i++) {
		// 		days.push(new Day(date.clone(), TASKBOOK.tasks));
		// 		date.add(1, "day");
		// 	}
		// 	return days;
		// },
		...mapGetters([
			TASKBOOK,
			TASKBOOK_DAYS
		]),
		taskPage() {
			return this.$store.state.taskBook.taskPageLayout.component
		}
		
	}
}
</script>



<style>
#print-area {
	background-color: white!important;
	color: black
}
.section-title {
	color: grey;
	text-transform: uppercase;
	text-align: left;
	border-bottom: 1px solid #ddd;
	height: 24px;
	width: 100%;
	font-weight: 300;
	letter-spacing: .05em
}
</style>