<template>
	<div style="color: grey;
	text-transform: uppercase;
	text-align: left;
	border-bottom: 1px solid #ddd;
	height: 24px;
	width: 100%;
	font-weight: 300;
	letter-spacing: .05em">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: "SectionTitle"
}
</script>


<style scoped>

</style>