

<template>
	<PrintPage>
		<div class="cover-page" style="display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	background-image: url('');
	background-size: cover;
	background-position: center;">
			<div class="cover-page__title">
				<div style="font-size: 64px; font-weight: 800">{{taskBook.coverPage.props.title}}</div>
			</div>
			<div class="cover-page__subtitle">
				<h2>{{taskBook.coverPage.props.subtitle}}</h2>
			</div>
<!--			<div class="cover-page__date">-->
<!--				<h3>{{taskBook.info.startDate}} to {{taskBook.info.endDate}}</h3>-->
<!--			</div>-->
		</div>
	</PrintPage>
</template>

<script>

import {defineComponent} from "vue";
import PrintPage from "@/components/PrintPage.vue";
import TaskBook from "@/js/TaskBook";

export default defineComponent({
	name: "CoverPage",
	components: {PrintPage},
	props: {
		taskBook: {
			type: TaskBook,
			required: true
		}
	}
})
</script>

<style >
.cover-page {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	
	background-size: cover;
	background-position: center;
}

</style>