<template>
	<div class="def-logo">
		<DefLogo
			style="height: 24px"
			@click="toggleTheme"
			:color="defColor"
		/>
	</div>
</template>

<script>
import DefLogo from "@/components/DefLogo.vue";
import {SET_LOGO} from "@/store/actions";
import LogoDarkRed from "@/assets/logo-dark-red.svg";
import LogoLightBlue from "@/assets/logo-light-blue.svg";

export default {
	name: "DefThemeToggle",
	components: {DefLogo},
	methods: {
		toggleTheme() {
			this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
			this.$store.dispatch(SET_LOGO, this.$vuetify.theme.dark ? LogoDarkRed : LogoLightBlue)
		}
	},
	computed: {
		defColor() {
			return this.$vuetify.theme.dark ? '#f00' : '#bdbdbd'
		}
	}
}
</script>


<style scoped>
.def-logo {
	position: absolute;
	bottom: 10px;
	left: 10px
}

.def-logo:hover {
	cursor: pointer;
}
</style>