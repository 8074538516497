<template>
	<v-container v-bind="$props">
		<slot></slot>
	</v-container>
</template>

<script>
export default {
	name: "AppContainer",
	props: {}
	
}
</script>


<style scoped>

</style>