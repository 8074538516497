<template>
	<div>
		<Builder ></Builder>
		
		
	</div>
</template>

<script>
// @ is an alias to /
import Task from "@/components/Task.vue";
import moment from "moment";
import TaskList from "@/components/TaskList.vue";
import Day from "@/js/Day";
import {testTasks} from "@/js/TestTasks";
import {frequencyTypes, weekdays} from "@/js/constants";
import PrintPage from "@/components/PrintPage.vue";
import CoverPage from "@/components/CoverPage/index.vue";
import TaskBook from "@/js/TaskBook";
import Calendar from "@/components/Calendar";
import TasksBuilder from "@/components/Builder/TasksBuilder/index.vue";
import Builder from "@/components/Builder/index.vue";
import BookDrawer from "@/components/Builder/BookDrawer/index.vue";
import AppBtn from "@/components/AppBtn.vue";
import TaskBookDocument from "@/components/TaskBookDocument/index.vue";
import {RESET_TASK_BOOK, SET_LOGO} from "@/store/actions";
import defSvg from '@/assets/def.svg'
import DefLogo from "@/components/DefLogo.vue";
import LogoDarkRed from '@/assets/logo-dark-red.svg'
import LogoLightBlue from '@/assets/logo-light-blue.svg'
import DefThemeToggle from "@/components/DefThemeToggle.vue";
export default {
	name: 'Test',
	components: {
		DefThemeToggle,
		DefLogo,
		AppBtn,
		BookDrawer,
		Builder,
		TasksBuilder,
		Calendar,
		CoverPage,
		PrintPage,
		Task,
		TaskList,
		TaskBookDocument,
		defSvg
	},
	props: {
		
	},
	data() {
		return {
			// startDate: moment("2024-01-01").format("YYYY-MM-DD"),
			// endDate: moment("2024-01-31").format("YYYY-MM-DD"),
			// taskBook: null,
			// days: [],
			// getAfterItTasks: [],
			// morningTasks: [],
			// frequencyTypes,
			// weekdays,
			// datePickerMenu: false,
			//
			// pages: null,
			//
			// drawer: true,
			// items: [
			// 	{ title: 'Home', icon: 'mdi-view-dashboard' },
			// 	{ title: 'About', icon: 'mdi-forum' },
			// ],
			// mini: true
		}
	},
	mounted() {
		this.init()
		
	},
	methods: {
		init() {
			this.$store.dispatch(RESET_TASK_BOOK)
		},

		// createDays() {
		// 	let days = [];
		// 	let date = moment(this.taskBook.info.startDate);
		// 	for (let i = 0; i < date.daysInMonth(); i++) {
		// 		days.push(new Day(date, testTasks));
		// 		date.add(1, "day");
		// 	}
		// 	this.days = days;
		// },
		// addTask() {
		// 	this.tasks.push({
		// 		label: "",
		// 		frequency: null,
		// 		on: null,
		// 		from: null,
		// 		to: null,
		// 	})
		// },
		// recalculate() {
		// 	this.createDays()
		// },
		// deleteTask(index) {
		// 	// delete task from array
		// 	this.tasks.splice(index, 1)
		// },
		
	},
	computed: {
		// defColor() {
		// 	return this.$vuetify.theme.dark ? '#f00' : '#bdbdbd'
		// }
		// days() {
		// 	const {tasks, taskBook} = this;
		// 	if (!taskBook) return
		// 	let days = [];
		// 	let date = moment(taskBook.info.startDate).clone();
		// 	for (let i = 0; date.isSameOrBefore(taskBook.info.endDate); i++) {
		// 		days.push(new Day(date.clone(), tasks));
		// 		date.add(1, "day");
		// 	}
		// 	return days;
		// }
		
	}
	
}
	
</script>

<style scoped>


.drawer-mini {
	height: 100vh; 
	background-color: #757575; 
	width: 56px; 
	writing-mode: vertical-rl; 
	position: sticky; 
	top: 0; 
	text-orientation: mixed; 
	display: flex; 
	justify-content: center; 
	align-items: center
}
</style>
