<template>
	<PrintPage>
		<TaskPageHeader2 :day="day"></TaskPageHeader2>
		<div style="display: flex; flex-direction: column; height: 8in" >
			<div style="flex: 2; padding-right: 10px">
				<TaskList :tasks="day.tasks"></TaskList>
			</div>
			<div style="flex: 1; padding-left: 10px">
				<div class="section-title">Notes</div>
			</div>
		</div>
		
		<div style="display: flex; justify-content: center; padding-top: 10px; color: #bdbdbd">{{taskBook.info.motto}}</div>
	</PrintPage>
</template>

<script>
import TaskList from "@/components/TaskList.vue";
import PrintPage from "@/components/PrintPage.vue";
import TaskPageHeader2 from "@/components/TaskBookDocument/TaskPageHeaders/TaskPageHeader2.vue";

export default {
	name: "TaskPage3",
	components: {TaskPageHeader2, PrintPage, TaskList},
	props: {
		day: {
			required: true
		},
		taskBook: {
			required: true
		}
	}
}
</script>


<style scoped>


</style>