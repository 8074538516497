<template>
	<div>
		<v-app-bar
			color="#080934"
			dense
			dark
			:elevation="0"
		>
			<v-app-bar-nav-icon></v-app-bar-nav-icon>

				<v-img
					alt="Tasko Logo"
					class="shrink mt-1 hidden-sm-and-down"
					contain
					:src="logo"
					height="80%"
					position="left"
				/>

			        
			
			<v-spacer></v-spacer>
			
<!--			<v-btn icon>-->
<!--				<v-icon>mdi-heart</v-icon>-->
<!--			</v-btn>-->
<!--			-->
<!--			<v-btn icon>-->
<!--				<v-icon>mdi-magnify</v-icon>-->
<!--			</v-btn>-->
			
			<v-menu
				left
				bottom
			>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						icon
						v-bind="attrs"
						v-on="on"
					>
						<v-icon>mdi-dots-vertical</v-icon>
					</v-btn>
				</template>
				
				<v-list>
					<v-list-item
						v-for="n in 5"
						:key="n"
						@click="() => {}"
					>
						<v-list-item-title>Option {{ n }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-app-bar>
	</div>
</template>

<script>
import logo from "@/assets/logo-dark.svg"

export default {
	name: 'NavBar',
	data() {
		return {
			logo: logo
		}
	},
	
}
</script>

<style>

</style>

