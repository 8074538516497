<template>


			<AppContainer >
				<AppRow>
					<AppCol :cols="3">
						Jump to:
						<div style="display: flex; flex-direction: column">
							<div v-for="(category, index) in taskCategories" :key="index" class="mt-1" >
								<a :href="'#' + category.sectionId" @click="() => goToCategorySection(category.sectionId)" >
									{{category.label}}
								</a>
							</div>
						
						</div>
					</AppCol>
					<AppCol :cols="9" class="task-selector-wrapper">
						<AppRow v-for="(category, index) in tasksByCategories" :key="index" :id="category.sectionId">
							<AppCol :cols="12">
								<div class="text-h6" style="font-weight: bold">{{category.label}}</div>
							</AppCol>
							<AppCol :cols="4" v-for="(task, taskIndex) in category.tasks" :key="taskIndex">
								<TaskCard 
									:task="task" 
									@click="addTask(task, index, taskIndex)"
									:isSelected="selectedPremadeTasks.includes(task.premadeTaskId)"
								></TaskCard>
							</AppCol>
						</AppRow>
					
					</AppCol>
				</AppRow>
			</AppContainer>


</template>

<script>
import AppContainer from "@/components/AppContainer.vue";
import BuilderPageTitle from "@/components/Builder/BuilderPageTitle.vue";
import TaskCard from "@/components/Builder/TaskSelector/TaskCard.vue";
import {taskCategories} from "@/js/constants";
import AppRow from "@/components/AppRow.vue";
import AppCol from "@/components/AppCol.vue";
import AppDivider from "@/components/AppDivider.vue";
import BuilderLayout from "@/components/Builder/BuilderLayout.vue";
import {premadeTasks} from "@/js/constants";
import {ADD_NEW_TASK} from "@/store/actions";
import Task from "@/js/Task";

export default {
	name: "TaskSelector",
	components: {BuilderLayout, AppDivider, AppCol, AppRow, TaskCard, BuilderPageTitle, AppContainer},
	data() {
		return {
			premadeTasks,
			tasks: [],
			taskCategories,
			tasksByCategories: [],
			selectedPremadeTasks: []
		}
	},
	mounted() {
		this.init()
	},
	computed: {

		
	},
	methods: {
		init() {
			this.getPremadeTasksByCategory()
		},
		addTask(task, categoryIndex, taskIndex) {
			this.getPremadeTasksByCategory(task, categoryIndex, taskIndex)
			this.$store.dispatch(ADD_NEW_TASK, task)
		},
		getPremadeTasksByCategory(task, categoryIndex, taskIndex) {
			// create an array of categories with the tasks associated with that category
			let categories = taskCategories.map(category => {
				return {
					...category,
					tasks: this.premadeTasks.filter(task => {
						return task.taskCategoryIds.find(id => id === category.id)
					})
				}
			})
			// console.log(categories)
			if (categoryIndex !== undefined && taskIndex !== undefined) {
				categories[categoryIndex].tasks[taskIndex].count++
			}
			this.tasksByCategories = categories;    
		},
		goToCategorySection(category) {
			console.log(category)
		},
		toggleSelectTask(task) {
			if (this.selectedPremadeTasks.includes(task.premadeTaskId)) {
				// remove from array
				let index = this.selectedPremadeTasks.findIndex(id => id === task.premadeTaskId)
				this.selectedPremadeTasks.splice(index, 1)
			} else {
				// add to array
				this.selectedPremadeTasks.push(task.premadeTaskId)
			}
		}
	}
	
}
</script>


<style scoped>
.task-selector-wrapper {
	overflow-y: scroll;
	height: 100%;
	max-height: 500px;
} 
</style>