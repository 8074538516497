<template>
	<PrintPage>
		<h1 style="text-align: center; margin-bottom: 30px" >
			{{ monthDisplay }}
		</h1>
		
		<table style="width: 100%; height: 80%; border-collapse: collapse;">
			
			<thead>
			<tr>
				<th v-for="day in daysOfWeek" :key="day">{{ day }}</th>
			</tr>
			</thead>
			<tbody>
			<tr v-for="(week, index) in calendarTable" :key="index">
				<td 
					v-for="(day, dayIndex) in week" :key="dayIndex"
				    :style="{'background-color': !day?.date ? '#f5f5f5' : null}" 
				    style="width: 14.28%; border: 1px solid #ddd; color: #757575">
					<div style="display: flex; height: 100%; margin: 5px">
						<div v-if="day?.date">{{ day.date }}</div>
					</div>
					
					
				</td>
			</tr>
			</tbody>
		</table>
	</PrintPage>
</template>

<script>
import moment, {isMoment} from 'moment';
import PrintPage from "@/components/PrintPage.vue";

export default {
	components: {PrintPage},
	name: "Calendar",
	props: {
		startDate: {
			required: true,
		},
	},
	data() {
		return {
			daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
			// calendar: [],
			weeksInMonth: null,
			calendarTable: [],
		};
	},
	computed: {
		monthDisplay() {
			return moment(this.startDate).format("MMMM");
		},
	},
	watch: {
		startDate() {
			this.generateCalendarTable();
		},
	},
	mounted() {
		
		// debugger;
		// this.generateCalendar();
		this.generateCalendarTable()

	},
	methods: {
		getWeeksInMonth() {
			const {startDate} = this;
			// calculate the number of weeks in the month
			const firstDay = moment(startDate).clone().startOf('month');
			const lastDay = moment(startDate).endOf('month');
			const used = firstDay.weekday() + lastDay.date();
			let weeksInMonth = Math.ceil(used / 7);
			return weeksInMonth;
		},
		generateCalendarTable() {
			// TODO: Allow calendar to start on Monday
			let table = [];
			let weeksInMonth = this.getWeeksInMonth();
			let firstDayNumber = moment(this.startDate).clone().startOf('month').day();
			let daysInMonth = moment(this.startDate).clone().daysInMonth();
			let day = 1;
			let hasPassedFirstDay = false;
			let hasPassedLastDay = false
			for (let i = 0; i < weeksInMonth; i++) {
				let week = [];
				for (let j = 0; j < 7; j++) {
					if (j === firstDayNumber) {
						hasPassedFirstDay = true;
					}
					if (day > daysInMonth) {
						hasPassedLastDay = true;
					}
					if (hasPassedFirstDay && !hasPassedLastDay) {
						week.push({
							date: day
						});
						day++;
					} else
					week.push(null);
				}
				table.push(week);
				if (i === firstDayNumber) {
					hasPassedFirstDay = true;
				}
			}
			
			this.calendarTable = table;
		},
		// generateCalendar() {
		// 	const calendar = [];
		// 	const startOfMonth = this.startMoment.clone().startOf('month');
		// 	const endOfMonth = this.startMoment.clone().endOf('month');
		// 	const currentDay = startOfMonth.clone().startOf('week');
		//	
		// 	while (currentDay.isBefore(endOfMonth)) {
		// 		const week = Array(7).fill(null).map(() => {
		// 			const day = currentDay.clone();
		// 			const disabled = !day.isSame(startOfMonth, 'month');
		//			
		// 			return {
		// 				date: day.date(),
		// 				disabled,
		// 			};
		// 		});
		//		
		// 		calendar.push(week);
		// 		currentDay.add(1, 'week');
		// 	}
		//
		//
		//	
		// 	this.calendar = calendar;
		// },
	},
	
};
</script>

<style scoped>


table {
	border-collapse: collapse;
}

td {
	border: 1px solid #ddd;
	color: #757575
}
</style>