<template>
	<div class="text-h4" style="font-weight: 700">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: "BuilderPageTitle"
}
</script>


<style scoped>

</style>