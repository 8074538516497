

<template>
	<div v-if="$store?.state?.taskBook">
<!--		<AppBtn @click="print">Print</AppBtn>-->

			<div class="beta-banner" style="">
				<strong class="pr-2">BETA:</strong> Please submit your feedback 
				<a 
					href="https://docs.google.com/forms/d/e/1FAIpQLSf5l2wMdXSyshcCUiAoDYr_WQ5-GUSdlWe6wT4EEzv-GtfMKA/viewform?usp=sf_link" 
					target="_blank" 
					class="ml-1"
					style="color: white"
				>
					here
				</a>.
			
			</div>

		<v-banner
			sticky
			style="padding-top: 0!important; padding-bottom: 0; margin: 0"
		>
			
			<div style="display: flex; align-items: center" @click="goToHome"
			     class="cursor-pointer">
				<img 
					:src="CURRENT_LOGO" 
					alt="" 
					style="height: 30px"
					
				>
				<h3 class="ml-2" style="font-size: 24px; color: #757575; font-weight: 300">
					Builder
				</h3>
			</div>
			
		</v-banner>
		<AppContainer>
			<AppTabs v-model="currentTab">
				<AppTab
					v-for="(tab, index) in tabs"
					:key="index"
				>
					{{ tab.label }}
				</AppTab>
			</AppTabs>
		</AppContainer>

		<AppContainer>

<!--				<Welcome-->
<!--					v-if="currentTab === 0"-->
<!--					@next="currentTab++"-->
<!--				/>-->


<!--				<TaskSelector -->
<!--					v-else-if="currentTab === 1"-->
<!--					v-model="taskBook.tasks" -->
<!--					@next="currentTab++"-->
<!--					@previous="currentTab&#45;&#45;"-->
<!--				></TaskSelector>-->
			
			<TasksBuilder
				v-if="currentTab === 0"

				@next="currentTab++"
				@previous="currentTab--"
			></TasksBuilder>
			<TaskPageLayoutSelector
				v-if="currentTab === 1"
			/>
			
			<InfoBuilder
				v-else-if="currentTab === 2"
				v-model="$store.state.taskBook.info"
				@next="currentTab++"
				@previous="currentTab--"
			></InfoBuilder>
<!--			-->
<!--			<CoverPageBuilder -->
<!--				v-model="taskBook.coverPage"-->
<!--				v-else-if="currentTab === 4"-->
<!--				@next="currentTab++"-->
<!--				@previous="currentTab&#45;&#45;"-->
<!--			></CoverPageBuilder>-->
			<div
				v-show="currentTab === 3"
				
				
			>
				<TaskBookDocument
					@print="print"
					:taskBook="$store.state.taskBook"
					v-if="$store.state.taskBook"
				></TaskBookDocument>
			</div>
			<DefThemeToggle />


<!--			<v-stepper alt-labels elevation="0" style="background: none" v-if="currentTab !== 0">-->
<!--				<v-stepper-header >-->
<!--					<template v-for="(step, index) in steps" v-if="index > 0">-->
<!--						<v-stepper-step -->
<!--							:key="index" -->
<!--							:complete="step.value < currentTab" -->
<!--							:step="index"-->
<!--							-->
<!--						>-->
<!--							{{ step.label }}-->

<!--						-->
<!--						</v-stepper-step>-->
<!--						<v-divider v-if="index !== steps.length - 1 "></v-divider>-->
<!--						-->
<!--					</template>-->
<!--					-->
<!--					-->
<!--					-->
<!--				</v-stepper-header>-->
<!--			</v-stepper>-->
		</AppContainer>
		

	</div>
</template>

<script >
import TasksBuilder from "@/components/Builder/TasksBuilder/index.vue";

import TaskBook from "@/js/TaskBook";
import CoverPageBuilder from "@/components/Builder/CoverPageBuilder/index.vue";
import InfoBuilder from "@/components/Builder/InfoBuilder/index.vue";
import Welcome from "@/components/Builder/Welcome/index.vue";
import AppTabs from "@/components/Tabs/AppTabs.vue";
import AppTab from "@/components/Tabs/AppTab.vue";
import AppBtn from "@/components/AppBtn.vue";
import TaskSelector from "@/components/Builder/TaskSelector/index.vue";
import AppContainer from "@/components/AppContainer.vue";
import TaskBookDocument from "@/components/TaskBookDocument/index.vue";
import TaskPageLayoutSelector from "@/components/Builder/TaskPageLayoutSelector/index.vue";
import LogoLightBlue from '@/assets/logo-light-blue.svg'
import LogoDarkRed from '@/assets/logo-dark-red.svg'
import {mapGetters} from "vuex";
import {CURRENT_LOGO} from "@/store/getters";
import DefThemeToggle from "@/components/DefThemeToggle.vue";
import {RESET_TASK_BOOK} from "@/store/actions";

export default {
	name: "Builder",
	components: {
		DefThemeToggle,
		TaskPageLayoutSelector,
		TaskBookDocument,
		AppContainer,
		TaskSelector, AppBtn, CoverPageBuilder, TasksBuilder, InfoBuilder, AppTabs, AppTab, Welcome},
	props: {
	},
	data() {
		return {
			currentTab: 0,
			tabs: [
				// {
				// 	label: "Welcome",
				// 	value: 0,
				// 	page: Welcome
				// },

				{
					label: "Tasks",
					value: 1,
					page: TasksBuilder
				},
				{
					label: "Layout",
					value: 1,
					page: TasksBuilder
				},
				{
					label: "Info",
					value: 2,
					page: InfoBuilder
				},
				{
					label: "Task Book",
					value: 3,
					page: InfoBuilder
				},
				// {
				// 	label: "Personalize",
				// 	value: 4,
				// 	page: Welcome
				// },
			],
			// steps: [
			// 	{
			// 		label: "Welcome",
			// 		value: 0,
			// 	},
			// 	{
			// 		label: "Info",
			// 		value: 1,
			// 	},
			// 	{
			// 		label: "Select Tasks",
			// 		value: 2,
			// 	},
			// 	{
			// 		label: "Customize Tasks",
			// 		value: 3,
			// 	},
			// 	{
			// 		label: "Personalize",
			// 		value: 4,
			// 	},
			//	
			// ]
		}
	},
	mounted() {
		this.init()
		
	},
	methods: {
		init() {
			if (!this.$store.state.taskBook) {
				this.$store.dispatch(RESET_TASK_BOOK)
			}
			
		},
		goToHome() {
			
			this.$router.push('/')
		},
		print() {
			// STACKOVERFLOW: https://stackoverflow.com/questions/52343006/how-to-print-a-part-of-a-vue-component-without-losing-the-style
			const prtHtml = document.getElementById('print-area').innerHTML;

// Get all stylesheets HTML
			let stylesHtml = '';
			let nodes = document.querySelectorAll('link[rel="stylesheet"], style');
			// debugger;
			for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {

				
				if (node?.href) {

					// stylesHtml += `<link rel="stylesheet" href="${`https://taskobook.com` + node.href}" />`;
					let nodeClone = node.cloneNode(true);
					// nodeClone.href = `https://taskobook.com` + nodeClone.href;
					stylesHtml += `<link rel="stylesheet" href="${node.href}" />`;
				}
				
				stylesHtml += node.outerHTML;
				
			}``


// Open the print window
			const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

			
			WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
  <link rel="stylesheet" href="//fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&amp;lang=en" />
  <link href="https://fonts.cdnfonts.com/css/sf-pro-display" rel="stylesheet">
                
    ${stylesHtml}
    
  </head>
  <body>
    ${prtHtml}
  </body>
</html>`);
			
			// WinPrint.document.close();
			WinPrint.focus();
			WinPrint.print();
			// WinPrint.close();
		}
	},
	computed: {
		// currentPage() {
		// 	return this.tabs.find(t => t.value === this.currentTab).page
		// }
		...mapGetters([
			CURRENT_LOGO
		]),
		// logo() {
		// 	return this.$vuetify.theme.dark ? LogoDarkRed : LogoLightBlue
		// }
	}
}

</script>

<style>
.beta-banner {
	background-color: var(--v-secondary-base); 
	width: 100%; 
	display: flex; 
	justify-content: center;
	color: white;
	padding: 4px 0
}
</style>
