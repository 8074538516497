<template>
	<v-container fluid style="height: 100%">
		<v-row style="height: 100%">
			<v-col cols="5" style="height: 100%; display: flex; justify-content: center" class="ma-0 pa-0">
				<AppContainer style="height: 100%; max-width: 600px" class="ma-0" >
					
					<div style="display: flex; justify-content: center; align-items: flex-start; flex-direction: column; height: 100%" class="pa-10">
						<img :src="CURRENT_LOGO" alt="" style="height: 60px" class="mb-4">

						
						<div class="text-h3 font-weight-black">
							You are the general.
						</div>
						<div class="text-h4 mt-4" >
							You make the orders.
						</div>
						<div class="mt-4 text-center" >
							Build your strategy.
						</div>
						<div>
							Give your orders.
						</div>
						<div>
							Print your Task Book.
						</div>
						<div>
							Execute your plan.
						</div>
						<v-alert
							color="primary"
							text
							class="mt-4"
							dense
						>
							<div style="display: flex; align-items: center">
								<img
									src="https://upload.wikimedia.org/wikipedia/commons/e/e1/Google_Chrome_icon_%28February_2022%29.svg" alt=""
									style="height: 24px"
								>
								<div class="ml-2">
									This tool has been optimized for Chrome.
								</div>
							
							</div>
						</v-alert>
						
						<AppBtn
							class="mt-4"
							@click="$router.push('/builder')"
						>
							Build My Taskbook
						</AppBtn>
					</div>
				</AppContainer>
			</v-col>
			<v-col cols="7" class="home-hero">
			</v-col>
		</v-row>
		<DefThemeToggle />
		<router-view></router-view>
	</v-container>
</template>

<script>
import DefThemeToggle from "@/components/DefThemeToggle.vue";
import AppBtn from "@/components/AppBtn.vue";
import AppContainer from "@/components/AppContainer.vue";
import AppLogo from "@/components/AppLogo.vue";
import {mapGetters} from "vuex";
import {CURRENT_LOGO} from "@/store/getters";

export default {
	name: "Welcome",
	components: {AppLogo, AppContainer, AppBtn, DefThemeToggle},
	computed: {
		...mapGetters([
			CURRENT_LOGO
		]),
	}
	
}
</script>


<style scoped>
.home-hero {
	background-image: url("~@/assets/Task-book-mockup.jpg");
	background-position: center;background-size: cover;
}
</style>