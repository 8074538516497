<template>
	<v-row v-bind="$props">
		<slot></slot>
	</v-row>
</template>

<script>
export default {
	name: "AppRow"
}
</script>


<style scoped>

</style>