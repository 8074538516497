import Vue from 'vue'
import Vuex from 'vuex'
import TaskBook from "@/js/TaskBook";
import {RESET_TASK_BOOK, UPDATE_TASK, ADD_NEW_TASK, DELETE_TASK, SET_PAGE_LAYOUT, SET_LOGO} from "@/store/actions";
import {TASKBOOK_TASKS, TASKBOOK, TASKBOOK_DAYS, TASK_PAGE_LAYOUT, CURRENT_LOGO} from "@/store/getters";
import moment from "moment/moment";
import Task from "@/js/Task";
import LogoLightBlue from '@/assets/logo-light-blue.svg'
import LogoDarkRed from '@/assets/logo-dark-red.svg'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      taskBook: null,
      currentLogo: LogoLightBlue
  },
  getters: {
      [TASKBOOK_TASKS]: state => state.taskBook.tasks,
      [TASKBOOK]: state => state.taskBook,
      [TASKBOOK_DAYS]: state => state.taskBook.getDays(),
      [TASK_PAGE_LAYOUT]: state => state.taskBook.taskPageLayout,
      [CURRENT_LOGO]: state => state.currentLogo
      
  },
  mutations: {
        [RESET_TASK_BOOK](state) {
            state.taskBook = new TaskBook()
            state.taskBook.info.startDate = moment().format("YYYY-MM-DD");
            state.taskBook.info.endDate = moment().add(1, "month").format("YYYY-MM-DD");
        },
      [UPDATE_TASK](state, payload) {
            state.taskBook.tasks[payload.index] = payload.task;
        },
      [ADD_NEW_TASK](state, task) {
            state.taskBook.tasks.push(new Task(task));
        },
      [DELETE_TASK](state, payload) {
            state.taskBook.tasks.splice(payload.index, 1);
        },
        [SET_PAGE_LAYOUT](state, payload) {
                state.taskBook.taskPageLayout = payload;
            },
        [SET_LOGO](state, payload) {
                state.currentLogo = payload;
            }
  },
  actions: {
      [RESET_TASK_BOOK](context) {
          context.commit(RESET_TASK_BOOK)
      },
      [UPDATE_TASK](context, payload) {
          context.commit(UPDATE_TASK, payload)
      },
      [ADD_NEW_TASK](context, task) {
          context.commit(ADD_NEW_TASK, task)
      },
      [DELETE_TASK](context, payload) {
          context.commit(DELETE_TASK, payload)
      },
      [SET_PAGE_LAYOUT](context, payload) {
          context.commit(SET_PAGE_LAYOUT, payload)
      },
      SET_LOGO(context, payload) {
          context.commit(SET_LOGO, payload)
      }
  },
  modules: {
  }
})
