<template>
	<BuilderLayout 
		title="Customize Book" 
		message="Add your own personal touch."
		@next="$emit('next')"
		@previous="$emit('previous')"
	>
		<AppRow>
			<AppCol :cols="6">
				<AppRow>
					<AppCol :cols="12">
						<AppDatePicker v-model="TASKBOOK.info.startDate" :max="TASKBOOK.info.endDate" label="Tasko Book Start Date"></AppDatePicker>
					</AppCol>
					<AppCol :cols="12">
						<AppDatePicker v-model="TASKBOOK.info.endDate" :min="TASKBOOK.info.startDate" label="Tasko Book End Date"></AppDatePicker>
					</AppCol>
					<AppCol :cols="12">
						<AppTextField v-model="TASKBOOK.info.motto" label="Motto" outlined dense></AppTextField>
					</AppCol>
				</AppRow>
			</AppCol>
			<AppCol :cols="6">
				<AppRow>
					<AppCol :cols="12">
						<AppTextField v-model="TASKBOOK.coverPage.props.title" label="Cover Page Title" outlined dense></AppTextField>
					</AppCol>
					<AppCol :cols="12">
						<AppTextField v-model="TASKBOOK.coverPage.props.subtitle" label="Cover Page Subtitle" outlined dense></AppTextField>
					</AppCol>
<!--					<AppCol :cols="12">-->
<!--						<AppTextarea v-model="TASKBOOK.goals" label="Goals" outlined dense></AppTextarea>-->
<!--					</AppCol>-->
				</AppRow>
			</AppCol>
		</AppRow>
		
		
		
	</BuilderLayout>
</template>

<script>

import {defineComponent} from "vue";
import AppDatePicker from "@/components/Forms/AppDatePicker.vue";
import AppTextField from "@/components/Forms/AppTextField.vue";
import BuilderLayout from "@/components/Builder/BuilderLayout.vue";
import {mapGetters} from "vuex";
import {TASKBOOK} from "@/store/getters";
import AppRow from "@/components/AppRow.vue";
import AppCol from "@/components/AppCol.vue";
import AppTextarea from "@/components/Forms/AppTextArea.vue";

export default defineComponent({
	components: {AppTextarea, AppCol, AppRow, BuilderLayout, AppTextField, AppDatePicker},
	name: "InfoBuilder",
	computed: {
		...mapGetters([
			TASKBOOK,
		]),
	},
})
</script>

<style scoped>

</style>