import Vue from 'vue'
import VueRouter from 'vue-router'
import ShelbyPlanner from '../views/ShelbyPlanner.vue'
import HomeView from '../views/HomeView.vue'
import PettiPlanner from "@/views/PettiPlanner.vue";
import Test from "@/views/Test.vue";
import Builder from "@/components/Builder/index.vue";
import Welcome from "@/views/Welcome.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
      children: [
          {
              path: 'builder',
              name: 'builder',
              component: Builder
          },
          {
              path: '',
              name: 'welcome',
              component: Welcome
          },
          {
              path: '/jocko',
              name: 'jocko',
              component: Welcome
          },
      ]
  },
    // {
    //     path: '/jocko',
    //     name: 'jocko',
    //     component: HomeView
    // },
  {
    path: '/shelby',
    name: 'shelby',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ShelbyPlanner
  },
    {
        path: '/petti',
        name: 'petti',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: PettiPlanner
    },
    // {
    //     path: '/builder',
    //     name: 'builder',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: Builder
    // },
    {
        path: '/jocko',
        name: 'jocko',
        component: HomeView
    },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
    // console.log('beforeEach', to, from)
    next()
}, error => {})

export default router
