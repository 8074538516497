<template>
	<div style="display: flex">
		<div style="display: flex; flex-direction: column">
			<div class="task-page-header" style="padding-bottom: 20px;">
				<div class="task-page-header-day" style="font-weight: 800; font-size: 24px">
					{{ day.day }}
				</div>
				<div class="task-page-header-date" style="font-weight: 300; font-size: 16px; color: grey; ">
					{{ day.displayDate }}
				</div>
			</div>
		</div>
		
<!--		<div style="margin-left: 20px; color: grey; font-weight: 300; white-space: pre-wrap;">-->
<!--			{{ TASKBOOK.goals }}-->
<!--		</div>-->
	</div>
</template>

<script>
import {TASKBOOK} from "@/store/getters";
import {mapGetters} from "vuex";

export default {
	name: "TaskPageHeader1",
	props: {
		day: {
			required: true
		},
		
	},
	computed: {
		...mapGetters([TASKBOOK])
	}
}
</script>


<style scoped>

</style>