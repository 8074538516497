
import {frequencyTypes, taskPageLayouts} from "./constants";
import CoverPage from "@/components/CoverPage/index.vue";
import {testTasks} from "@/js/TestTasks";
import moment from "moment";
import Day from "@/js/Day";
export default class TaskBook {
    constructor() {
        this.info = {
            startDate: null,
            endDate: null,
            motto: "Getting better is a campaign"
        }
        this.coverPage = {
            coverPageId: null,
            props: {
                title: "Title",
                subtitle: "",
            }
        };
        this.goals = ``;
        this.tasks = [];
        this.holidays = [];
        this.events = [];
        this.taskPageLayout = taskPageLayouts[0];
    }
    
    getDays() {
        
        let days = [];
        let date = moment(this.info.startDate).clone();
        for (let i = 0; date.isSameOrBefore(this.info.endDate); i++) {
            days.push(new Day(date.clone(), this));
            date.add(1, "day");
        }
        return days;
    }

    // set a(val) {
    //     this._a = val;
    //     this._onPropertyChanged('a', val);
    // }
    //
    // _onPropertyChanged(propName, val) {
    //     // do something
    // }
}