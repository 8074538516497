<template>
	<v-divider></v-divider>
</template>

<script>
export default {
	name: "AppDivider"
}
</script>


<style scoped>

</style>