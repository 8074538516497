import { render, staticRenderFns } from "./TaskPage3.vue?vue&type=template&id=346a1468&scoped=true"
import script from "./TaskPage3.vue?vue&type=script&lang=js"
export * from "./TaskPage3.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "346a1468",
  null
  
)

export default component.exports