<template>
	<div>
		<PrintPage class="print-page" v-for="(day, index) in days" :key="index" >
<!--			<div class="header">-->
<!--				<div class="day">{{day.day}}</div>-->
<!--				<div class="date">{{day.displayDate}}</div>-->
<!--			</div>-->
			<TaskPageHeader1 :day="day"></TaskPageHeader1>
			<v-container fluid style="padding: 0">
				<div style="display: flex; height: 100%">
					<div class="col col-5" style="display: flex; flex-direction: column; height: 9in">
						<div class="section morning">
							
							<div class="section-title">Morning</div>
							<div>
								<TaskList :tasks="day.morningTasks"></TaskList>
							</div>
						</div>
						<div class="section morning">
							
							<div class="section-title">Day</div>
							<TaskList :tasks="day.dayTasks"></TaskList>
						</div>
						<div style="flex: 1">
							<div class="message">
								<div class="section-title">Be Better</div>
								<ol style="padding-left: 20px; font-size: 18px">
									<li>Shut your pie hole.</li>
									<li>Talk last.</li>
									<li>Ask questions that make people smarter and more specific.</li>
								</ol>
							</div>
						</div>
						<div class="section morning" v-if="!day.isWorkDay" >
							
							<div class="section-title" >WORK WRAP UP</div>
							<TaskList :tasks="day.workWrapUpTasks"></TaskList>
						</div>
						<div class="section morning">
							
							<div class="section-title">NIGHT</div>
							<TaskList :tasks="day.nightTasks"></TaskList>
						</div>
					</div>
					<div class="col col-7">
						<div class="section morning">
							
							<div class="section-title">Get After It</div>
							<TaskList :tasks="day.getAfterItTasks"></TaskList>
						</div>
					</div>
				</div>
			</v-container>
			
			
			<div class="footer">Getting better is a campaign</div>
		</PrintPage>
	</div>
</template>

<script>
// @ is an alias to /
import Task from "@/components/Task.vue";
import moment from "moment";
import TaskList from "@/components/TaskList.vue";
import PettiDay from "@/js/PettiDay";
import TaskPageHeader1 from "@/components/TaskBookDocument/TaskPageHeaders/TaskPageHeader1.vue";
import PrintPage from "@/components/PrintPage.vue";
export default {
	name: 'PettiPlanner',
	components: {
		PrintPage,
		TaskPageHeader1,
		Task,
		TaskList,
	},
	props: {
		
	},
	data() {
		return {
			startDate: moment("2024-03-01"),
			days: [],
			tasks: [],
			getAfterItTasks: [],
			morningTasks: [],
		}
	},
	mounted() {
		this.init()
	},
	methods: {
		init() {
			this.createDays();
		},
		createDays() {
			let days = [];
			let date = this.startDate;
			for (let i = 0; i < date.daysInMonth(); i++) {
				days.push(new PettiDay(date));
				date.add(1, "day");
			}
			this.days = days;
		},
		
	}
	
}

</script>
