<template>
	<v-col :cols="cols" @click="$emit('click')">
		<slot></slot>
	</v-col>

</template>

<script>
export default {
	name: "AppCol",
	props: {
		cols: {
			default: 12
		}
	}
}
</script>


<style scoped>

</style>