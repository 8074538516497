<template>
	<BuilderLayout title="Cover Page" @next="$emit('next')" @previous="$emit('previous')">
		I am the cover page builder!
		<AppTextField
			v-model="value.props.title"
			outlined
			dense
			hide-details
			label="Title"
		></AppTextField>
		<AppTextField
			v-model="value.props.subtitle"
			outlined
			dense
			hide-details
			label="Subtitle"
		></AppTextField>
	</BuilderLayout>
</template>

<script>
import AppTextField from "@/components/Forms/AppTextField.vue";
import BuilderLayout from "@/components/Builder/BuilderLayout.vue";

export default {
	name: "CoverPageBuilder",
	components: {BuilderLayout, AppTextField},
	props: {
		value: {
			required: true
		}
	}
}
</script>



<style scoped>

</style>