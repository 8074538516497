import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        options: { customProperties: true },
        dark: false,
        themes: {
            light: {
                primary: colors.blue.base, // #E53935
                secondary: "080934", // #FFCDD2
                accent: colors.blue.base, // #3F51B5
                background: colors.white,
                border: "#dddddd"
            },
            dark: {
                primary: colors.red.base, // #E53935
                secondary: colors.red.base, // #FFCDD2
                accent: colors.red.lighten3, // #3F51B5
            }
        },
    },
})

