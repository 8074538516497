import Task from "@/js/Task";
import TaskPage1 from "@/components/TaskBookDocument/TaskPages/TaskPage1.vue";
import TaskPage2 from "@/components/TaskBookDocument/TaskPages/TaskPage2.vue";
import TaskPage3 from "@/components/TaskBookDocument/TaskPages/TaskPage3.vue";

let shelbyHolidays = [
    {
        label: "New Year's Day",
        date: "January 1, 2024",
    },
    {
        label: "MLK Day",
        date: "January 15, 2024",
    },
    
];

let frequencyTypes = [
    {
        label: "Every day...",
        id: 1,
    },
    {
        label: "Every weekday... (Mon - Fri)",
        id: 2,
    },

    // {
    //     label: "Once on...",
    //     id: 3,
    // },
    {
        label: "Once a month, every...",
        id: 4,
    },
    {
        label: "First... of the month",
        id: 5,
    },
    {
        label: "Second... of the month",
        id: 6,
    },
    {
        label: "Third... of the month",
        id: 7,
    },
    {
        label: "Fourth... of the month",
        id: 8,
    },
    {
        label: "Last... of the month",
        id: 9,
    },
    {
        label: "Last day of the month...",
        id: 10,
    },
    {
        label: "Every week",
        id: 11,
    },
    {
        label: "Once every two weeks...",
        id: 12,
    },
]

let weekdays = [
    {
        label: "Sunday",
        id: 0,
    },
    {
        label: "Monday",
        id: 1,
    },
    {
        label: "Tuesday",
        id: 2,
    },
    {
        label: "Wednesday",
        id: 3,
    },
    {
        label: "Thursday",
        id: 4,
    },
    {
        label: "Friday",
        id: 5,
    },
    {
        label: "Saturday",
        id: 6,
    },
]

let daysOfMonth = []
for (let i =1; i <= 31; i++) {
    daysOfMonth.push({
        label: i.toString(),
        id: i,
    })

}

let taskCategories = [
    {
        label: "Popular",
        id: 1,
        sectionId: 'popular'
    },
    {
        label: "Health",
        id: 2,
        sectionId: 'health'
    },
    {
        label: "Work",
        id: 4,
        sectionId: 'work'
    },
    {
        label: "Home",
        id: 5,
        sectionId: 'home'
    },
    {
        label: "Personal Development",
        id: 6,
        sectionId: 'personal-development'
    },
    // {
    //     label: "Spiritual",
    //     id: 7,
    //     sectionId: 'spiritual'
    // },
    {
        label: "Habits",
        id: 8,
        sectionId: 'habits'
    },
    // {
    //     label: "Parenting",
    //     id: 9,
    //     sectionId: 'parenting'
    // },
    
];

let premadeTasksTemp = [
    {
        label: "Workout",
        frequencyType: 1,
        on: null,
        from: null,
        to: null,
        premadeTaskId: 1,
        taskCategoryIds: [1, 2]
    },
    {
        label: "Take Vitamins",
        frequencyType: 2,
        on: null,
        from: null,
        to: null,
        premadeTaskId: 2,
        taskCategoryIds: [2]
    },
    {
        label: "Eat □ B □ L □ D",
        frequencyType: 1,
        on: null,
        from: null,
        to: null,
        premadeTaskId: 2,
        taskCategoryIds: [2]
    },
    {
        label: "Water □ 1 □ 2 □ 3 □ 4",
        frequencyType: 1,
        on: null,
        from: null,
        to: null,
        premadeTaskId: 2,
        taskCategoryIds: [2]
    },
    
    {
        label: "Email 0",
        frequencyType: 2,
        on: 0,
        from: null,
        to: null,
        premadeTaskId: 3,
        taskCategoryIds: [4]
    },
    {
        label: "Task 0",
        frequencyType: 2,
        on: 0,
        from: null,
        to: null,
        premadeTaskId: 3,
        taskCategoryIds: [4]
    },
    {
        label: "Clean Work Space",
        frequencyType: 2,
        on: 0,
        from: null,
        to: null,
        premadeTaskId: 3,
        taskCategoryIds: [4]
    },
    {
        label: "Set Tomorrow Gear",
        frequencyType: 2,
        on: 0,
        from: null,
        to: null,
        premadeTaskId: 3,
        taskCategoryIds: [8]
    },
    {
        label: "Daily Devotional",
        frequencyType: 2,
        on: 0,
        from: null,
        to: null,
        premadeTaskId: 3,
        taskCategoryIds: [8]
    },
    {
        label: "Church",
        frequencyType: 2,
        on: 0,
        from: null,
        to: null,
        premadeTaskId: 3,
        taskCategoryIds: [8]
    },
    {
        label: "Read",
        frequencyType: 1,
        on: 0,
        from: null,
        to: null,
        premadeTaskId: 3,
        taskCategoryIds: [6]
    },
    {
        label: "Write",
        frequencyType: 1,
        on: 0,
        from: null,
        to: null,
        premadeTaskId: 3,
        taskCategoryIds: [6]
    },
    {
        label: "Remember your Why",
        frequencyType: 1,
        on: 0,
        from: null,
        to: null,
        premadeTaskId: 3,
        taskCategoryIds: [6]
    },
    {
        label: "Make kids meals",
        frequencyType: 1,
        on: 0,
        from: null,
        to: null,
        premadeTaskId: 3,
        taskCategoryIds: [9]
    },
    {
        label: "Take kid(s) to school",
        frequencyType: 1,
        on: 0,
        from: null,
        to: null,
        premadeTaskId: 3,
        taskCategoryIds: [9]
    },
    {
        label: "Meal Prep",
        frequencyType: 1,
        on: 0,
        from: null,
        to: null,
        premadeTaskId: 3,
        taskCategoryIds: [8]
    },
    {
        label: "Take out trash",
        frequencyType: 1,
        on: 0,
        from: null,
        to: null,
        premadeTaskId: 3,
        taskCategoryIds: [5]
    },
    {
        label: "Make Dinner",
        frequencyType: 1,
        on: 0,
        from: null,
        to: null,
        premadeTaskId: 3,
        taskCategoryIds: [5]
    },
    {
        label: "Make Breakfast",
        frequencyType: 1,
        on: 0,
        from: null,
        to: null,
        premadeTaskId: 3,
        taskCategoryIds: [5]
    },
    
]
let premadeTasks = premadeTasksTemp.map(x => new Task(x));

let taskPageLayouts = [
    {
        name: "TaskPage1",
        label: "Task Page 1",
        component: TaskPage1,
        id: 1,
        defaultTasksSectionLength: 24
    },
    {
        name: "TaskPage2",
        component: TaskPage2,
        label: "Task Page 2",
        id: 2,
        defaultTasksSectionLength: 24
    },
    // {
    //     name: "TaskPage3",
    //     component: TaskPage3,
    //     id: 3,
    //     defaultTasksSectionLength: 15
    // },

]

export {
    shelbyHolidays,
    frequencyTypes,
    weekdays,
    taskCategories,
    premadeTasks,
    taskPageLayouts,
    daysOfMonth
}