import { render, staticRenderFns } from "./TaskPage2.vue?vue&type=template&id=6295d553&scoped=true"
import script from "./TaskPage2.vue?vue&type=script&lang=js"
export * from "./TaskPage2.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6295d553",
  null
  
)

export default component.exports