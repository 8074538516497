<template>
	<AppCard class="task-card pa-2" @click="$emit('click')" :class="{'selected': isSelected}">
		<AppCard 
			class="task-card-count-wrapper" 
			v-if="task.count > 0"
		>
			{{task.count}}
		</AppCard>
		<div style="display: flex; flex-direction: column">
			<div class="task-label">{{task.label}}</div>
			<v-spacer></v-spacer>
<!--			<div class="text-body-2">{{ frequencyLabel }}</div>-->
			<div class="text-body-2">{{ task.getTaskDescription() }}</div>
		</div>
			
			
		
		
	</AppCard>
</template>

<script>

import {frequencyTypes} from "@/js/constants";
import AppCard from "@/components/AppCard.vue";
import AppContainer from "@/components/AppContainer.vue";
import moment from "moment";


export default {
	name: "TaskCard",
	components: {AppContainer, AppCard},
	props: {
		task: Object,
		isSelected: {
			type: Boolean,
			default: true
		
		}
	},
	methods: {
		
	},
	computed: {
		frequencyLabel() {
			debugger;
			let something = frequencyTypes.find(f => f.id === this.task.frequencyType);
			return something.label;
		},
		
	}
}
</script>


<style scoped >
.task-card {
	border-radius: 5px;
	border: 1px solid #ddd;
	transition: all 0.2s ease-in-out;
	height: 100%
}

.task-label {
	font-weight: 600
}

.task-card:hover {
	border: 1px solid var(--v-primary-base);
	cursor: pointer;
	transform: translateY(-2px);
	//background: red;
	
}

.selected {
	background: red;
}

.task-card-count-wrapper {
	position: absolute;
	top: -10px;
	right: -10px;
	border: 1px solid var(--v-primary-base);
	color: var(--v-primary-base);
	//background-color: var(--v-primary-background);
	height: 24px;
	width: 24px;
	border-radius: 50px!important;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px
}
</style>