<template>
	<AppContainer >
		<div style="display: flex; justify-content: center; align-items: center; flex-direction: column; min-height: 50vh">
			<div class="text-h3" >
				You are the general.
			</div>
			<div class="text-h4 mt-4" >
				You give the orders.
			</div>
			<div class="text-body-1 mt-4" >
				Tasko helps you be the general. Make your strategy. Give your orders. Tasko will help you execute.
			</div>
			<AppBtn
				class="mt-4"
				@click="$emit('next')"
			>
				Build My Taskbook
			</AppBtn>
		</div>
		
	</AppContainer>
</template>

<script>
import AppContainer from "@/components/AppContainer.vue";
import AppBtn from "@/components/AppBtn.vue";

export default {
	name: "Welcome",
	components: {AppBtn, AppContainer}
}
</script>


<style scoped>

</style>