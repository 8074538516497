<template>
	<div class="print-page" style="margin: 0px;
	padding: .5in;
	width: 8.5in; 
	height: 10.5in;
	clear: both;
	page-break-before: always;
	font-family: 'SF Pro Display', 'Open Sans', Arial, sans-serif;
	background-color: white;
	color: black" >
		<slot></slot>
	</div>
</template>

<script >
export default {
	name: 'PrintPage',
	
}
</script>

<style >
.print-page {
	margin: 0px;
	padding: .5in;
	width: 8.5in; /* width: 7in; */
	height: 11in; /* or height: 9.5in; */
	clear: both;

	page-break-before: always;

	font-family: 'SF Pro Display', "Open Sans", Arial, sans-serif;
	background-color: white;
	color: black
}

</style>