<template>
	<div>
		<BuilderLayout title="Customize Tasks" message="Customize away soldier!" @next="$emit('next')" @previous="$emit('previous')">
			
			<AppContainer v-if="TASKBOOK_TASKS.length === 0">
				<div class="empty-tasks-container">
					You have no tasks! Let's get started.
					<AppBtn @click="browseTasksDialogOpen = true" class="mt-2" outlined>Browse Tasks</AppBtn>
					<AppBtn @click="addTask" class="mt-2">Add Task</AppBtn>
				</div>
			</AppContainer>
			
			<v-container v-else>
				<v-row>
					<v-spacer>
					</v-spacer>
					<AppBtn @click="browseTasksDialogOpen = true" class="mr-2" outlined>Browse Tasks</AppBtn>
					<AppBtn @click="addTask" color="secondary">Add Task</AppBtn>
				</v-row>
				<v-row style="font-weight: bold">
					<v-col :cols="3">Task</v-col>
					<v-col :cols="2">Frequency</v-col>
					<v-col :cols="2">On</v-col>
					<v-col :cols="2">From</v-col>
					<v-col :cols="2">To</v-col>
					<v-col :cols="1"></v-col>
				</v-row>
				<v-row v-for="(task, index) in TASKBOOK_TASKS" dense>
					<v-col :cols="3">
						<AppTextField
							:value="task.label"
							@input="e => updateTask('label', e, task, index)"
							
							outlined
							dense
							hide-details
							
						/>
					</v-col>
					<v-col :cols="2">
						<AppSelect
							:value="task.frequencyType"
							@input="e => updateTask('frequencyType', e, task, index)"
							:items="frequencyTypes"
							item-text="label"
							item-value="id"
							outlined
							dense
							hide-details
						></AppSelect>
<!--						<v-menu-->
<!--							ref="menu"-->
<!--							v-model="frequencyTypeMenuOpen"-->
<!--							:close-on-content-click="false"-->
<!--							:return-value.sync="task.frequencyType"-->
<!--							transition="scale-transition"-->
<!--							offset-y-->
<!--							min-width="auto"-->
<!--						>-->
<!--							<template v-slot:activator="{ on, attrs }">-->
<!--								<v-text-field-->
<!--									:value="task.frequencyType"-->
<!--									readonly-->
<!--									v-bind="attrs"-->
<!--									v-on="on"-->
<!--									outlined-->
<!--									dense-->
<!--									hide-details-->
<!--								></v-text-field>-->
<!--							</template>-->
<!--							<v-list>-->
<!--								<v-list-item-->
<!--									v-for="(item, index) in frequencyTypes"-->
<!--									:key="index"-->
<!--								>-->
<!--									<v-list-item-title>{{ item.label }}</v-list-item-title>-->
<!--								</v-list-item>-->
<!--							</v-list>-->
<!--						</v-menu>-->

					</v-col>
					<v-col :cols="2" >
						<AppSelect
							v-if="task.frequencyType !== 3 && task.frequencyType !== 1 && task.frequencyType !== 2"
							:value="task.frequencyType"
							@input="e => updateTask('on', e, task, index)"
							:items="task.frequencyType === 4 ? daysOfMonth : weekdays"
							item-text="label"
							item-value="id"
							outlined
							dense
							hide-details
						></AppSelect>
					</v-col>
					<v-col :cols="2">
						<AppDatePicker 
							:value="task.from"
							@input="e => updateTask('from', e, task, index)"
							placeholder="Start of Book"
							
						></AppDatePicker>
					</v-col>
					<v-col :cols="2">
						<AppDatePicker
							:value="task.to"
							@input="e => updateTask('to', e, task, index)"
							placeholder="End of Book"
						></AppDatePicker>
					</v-col>
					<v-col :cols="1">
						<v-btn
							icon
							color="#bdbdbd"
							@click="() => deleteTask(index)"
						>
							<v-icon>mdi-delete-outline</v-icon>
						</v-btn>
					
					</v-col>
				
				
				
				
				</v-row>
<!--				<v-row >-->
<!--					<v-btn @click="addTask" color="secondary">Add Task</v-btn>-->
<!--				</v-row>-->
			
			</v-container>
			<AppDialog
				v-model="browseTasksDialogOpen"
				@close="browseTasksDialogOpen = false"
				:width="1000"
			>
				
					<TaskSelector />
				
			
			</AppDialog>
		</BuilderLayout>
		
	</div>
</template>

<script >

import {defineComponent} from "vue";
import AppDatePicker from "@/components/Forms/AppDatePicker.vue";
import {frequencyTypes, weekdays, daysOfMonth} from "@/js/constants";
import AppTextField from "@/components/Forms/AppTextField.vue";
import AppSelect from "@/components/Forms/AppSelect.vue";
import AppBtn from "@/components/AppBtn.vue";
import AppDialog from "@/components/AppDialog.vue";
import BuilderPageTitle from "@/components/Builder/BuilderPageTitle.vue";
import BuilderLayout from "@/components/Builder/BuilderLayout.vue";
import {TASKBOOK_TASKS} from "@/store/getters";
import {ADD_NEW_TASK, UPDATE_TASK, DELETE_TASK} from "@/store/actions";
import {mapGetters} from "vuex";
import TaskSelector from "@/components/Builder/TaskSelector/index.vue";
import AppContainer from "@/components/AppContainer.vue";

export default defineComponent({
	components: {
		AppContainer,
		TaskSelector,
		BuilderLayout, BuilderPageTitle, AppDialog, AppBtn, AppSelect, AppTextField, AppDatePicker},
	name: "TasksBuilder",
	props: {
		// value: {
		// 	required: true
		// },
	},
	data() {
		return {
			browseTasksDialogOpen: false,
			frequencyTypes,
			weekdays,
			daysOfMonth,
			items: [
				{ title: 'Click Me' },
				{ title: 'Click Me' },
				{ title: 'Click Me' },
				{ title: 'Click Me 2' },
			],
			frequencyTypeMenuOpen: false
		}
	},
	computed: {
		...mapGetters([
			TASKBOOK_TASKS,
		]),
	},
	methods: {
		updateTask(property, e, task, index) {
			task[property] = e;
			this.$store.dispatch(UPDATE_TASK, {task, index})
		},
		
		addTask() {
			this.$store.dispatch(ADD_NEW_TASK)
		},
		deleteTask(index) {
			this.$store.dispatch(DELETE_TASK, {index})

		},
	}
})
</script>



<style >
.v-text-field--outlined >>> fieldset {
	border-color: #eeeeee;
}

.empty-tasks-container {
	width: 100%;
	height: 200px;
	border: 1px dashed var(--v-primary-base);
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.v-text-field--outlined >>> fieldset {
	border-color: #eeeeee;
}
</style>