<template>
	<PrintPage>
		<TaskPageHeader1 :day="day"></TaskPageHeader1>
		<TaskList :tasks="day.tasks"></TaskList>
		<div style="display: flex; justify-content: center; padding-top: 10px; color: #bdbdbd">{{taskBook.info.motto}}</div>
	</PrintPage>
</template>

<script>
import TaskList from "@/components/TaskList.vue";
import PrintPage from "@/components/PrintPage.vue";
import TaskPageHeader1 from "@/components/TaskBookDocument/TaskPageHeaders/TaskPageHeader1.vue";

export default {
	name: "TaskPage1",
	components: {TaskPageHeader1, PrintPage, TaskList},
	props: {
		day: {
			required: true
		},
		taskBook: {
			required: true
		}
	}
}
</script>


<style scoped>

</style>