import moment from "moment";
import {shelbyHolidays} from "./constants";
export default class ShelbyDayV2 {
    constructor(date, businessDayNumber) {
        this.date = date;
        this.day = date.format("dddd");
        this.isWorkDay = moment(this.date).day() === 0 || moment(this.date).day() === 6;
        
        this.displayDate = date.format("MMMM D, yyyy");
        this.businessDayNumber = businessDayNumber;
        this.shelbyBusinessTasks = this.getShelbyBusinessTasks();
        this.tasks = this.getTasks();
        this.morningTasks = this.getMorningTasks();
        this.nightTasks = this.getNightTasks();
        this.dayTasks = this.getDayTasks();
    }

    getMorningTasks() {
        let tasks = [];
        tasks.push({
            label: "Workout",
        });
        tasks.push({
            label: "Vitamins",
        });
        tasks.push({
            label: "Devotional",
        });

        // if (moment(this.date).day() === 0) {
        //     tasks.push({
        //         label: "Church",
        //     });
        // }
        //
        // if (moment(this.date).day() === 2) {
        //     tasks.push({
        //         label: "Trash & Recycling",
        //     });
        // }
        //
        // if (moment(this.date).day() === 5) {
        //     tasks.push({
        //         label: "Trash",
        //     });
        // }
        //
        // if (moment(this.date).day() > 0 && moment(this.date).day() < 5) {
        //     tasks.push({
        //         label: "GG Hour",
        //     });
        // }
        //
        // if (moment(this.date).day() === 5) {
        //     tasks.push({
        //         label: "GG Drop Off",
        //     });
        // }




        return tasks;
    }

    getDayTasks() {
        let tasks = [];
        tasks.push({
            label: "Water □ 1 □ 2 □ 3 □ 4",
        });
        // tasks.push({
        //     label: "Food □ B □ L □ D",
        // });

        return tasks;
    };

    getNightTasks() {
        let tasks = [];
        // tasks.push({
        //     label: "Make Tomorrow Task List",
        // });
        // tasks.push({
        //     label: "Cold Tub",
        // });
        // tasks.push({
        //     label: "Family Time",
        // });
        // tasks.push({
        //     label: "Clean Bottles",
        // });
        // tasks.push({
        //     label: "Set Tomorrow Gear",
        // });
        tasks.push({
            label: "Clean Bottles",
        });
        tasks.push({
            label: "Pick Up",
        });
        if (moment(this.date).day() < 4) {
            tasks.push({
                label: "Make Breakfast & Lunch",
            });
        }
        return tasks;
    }
    
    getShelbyBusinessTasks() {
        let self = this;
        let tasks = []
        let shelbyBusinessTasks = [
            {
                // label: "Staff loads buyback volumes - Allocations must be ran first",
                label: "Staff loads buyback volumes",
                businessDay: 1,
            },
            {
                label: "P&A Overhead Review",
                businessDay: 1,
            },
            {
                label: "Coordinator Role - Corp Allocations",
                businessDay: 2,
            },
            {
                label: "Request supervisor time from Ashley Dovle",
                businessDay: 3,
            },
            {
                label: "Supervisor time posted",
                businessDay: 3,
            },
            {
                label: "Check that PR is closed for all DNs",
                businessDay: 3,
            },
            {
                label: "Coordinator Role - 3rd Day Checks",
                businessDay: 3,
            },
            {
                label: "Prod volumes to Flywheel",
                businessDay: 4,
            },
            {
                label: "Allocations",
                businessDay: 4,
            },
            {
                label: "Production tie-outs",
                businessDay: 4,
            },
            {
                label: "Review Flvwheel BL Check",
                businessDay: 5,
            },
            {
                label: "Review CAER BL Check inputs and PRA entry",
                businessDay: 6,
            },
            {
                // label: "Check that all Checks have been posted (Check PPD/CRW)",
                label: "Check that all Checks have been posted",
                businessDay: 6,
            },
            {
                label: "Check that all DN's are closed",
                businessDay: 6,
            },
            {
                label: "Check for open PPNs before rev close",
                businessDay: 6,
            },
            {
                label: "Review 910 rec and write off what staff turns in",
                businessDay: 6,
            },
            {
                label: "Revenue posted",
                businessDay: 6,
            },
            {
                label: "SLDD&A",
                businessDay: 7,
            },
            {
                // label: "Review Desoto, Merit Gathering, and Twin Groves allocations",
                label: "Review TIK Workbook Allocations",
                businessDay: 7,
            },
            {
                // label: "Pricing S/S Reconciliation (FAYOTHER Acct Prepares)",
                label: "Pricing S/S Reconciliation",
                businessDay: 7,
            },
            {
                label: "Staff pull down PC ACM reports",
                businessDay: 7,
            },
            {
                label: "1st Pass Accruals - Typically None",
                businessDay: 7,
            },
            {
                // label: "Staff update the Pricing file for variances review once completed",
                label: "Staff update the Pricing file for variances",
                businessDay: 7,
            },
            {
                label: "Tie-out PC ACM variances to Variance report",
                businessDay: 8,
            },
            {
                // label: "Do not post anything in new PE until taxes finalized.",
                label: "Do not post in new PE until taxes finalized.",
                businessDay: 8,
            },
            {
                label: "Review sales invoices to purchasers",
                businessDay: 9,
            },
            {
                label: "210.400 - FAYOTHER accountant to w/o lines",
                businessDay: 10,
            },
            {
                // label: "Out of tolerance owners - payment processing forward email to staff",
                label: "OOT Owners - payment processing forward",
                businessDay: 10,
            },
            {
                label: "OGORs reviewed & corrected",
                businessDay: 11,
            },
            {
                label: "Update Gas Balancing Files",
                businessDay: 11,
            },
            {
                label: "UOP DD&A",
                businessDay: 11,
            },
            {
                label: "930 REC",
                businessDay: 11,
            },
            {
                label: "Prepare CAER BL Invoice",
                businessDay: 11,
            },
            {
                label: "Review Monthly Payout Tracker",
                businessDay: 13,
            },
            {
                label: "Outlook DD&A",
                businessDay: 13,
            },
            {
                label: "AR report comes out",
                businessDay: 14,
            },
            {
                label: "Approve OES invoices for Desoto (Ks)",
                businessDay: 14,
            },
            {
                label: "TIK Noms review",
                businessDay: 15,
            },
            {
                label: "JV changes report",
                businessDay: 16,
            },
            {
                label: "Review AR",
                businessDay: 16,
            },
            {
                // label: "Forward Well DownTime/ Water Errors to Staff",
                label: "Forward Well DownTime/ Water Errors to Staff",
                businessDay: 16,
            },
            {
                // label: "Dunning request & updates Dunning Letter Tracker",
                label: "Dunning request & updates Letter Tracker",
                businessDay: 16,
            },
            {
                label: "Review the Pricing S/S",
                businessDay: 17,
            },
            {
                label: "Internal vs External BB Review",
                businessDay: 18,
            },

        ]
        shelbyBusinessTasks.forEach(task => {
            debugger;
            if (task.businessDay === self.businessDayNumber) {
                debugger;
                tasks.push({
                    label: task.label,
                });
            }
        })
        return tasks;
    }

    getTasks() {
        let self = this;
        
        
        let tasks = [];
        // tasks.push({
        //     label: "Take Vitamins",
        // });
        // tasks.push({
        //     label: "Water □ 1 □ 2 □ 3",
        // });
        // tasks.push({
        //     label: "Devotional",
        // });
        // tasks.push({
        //     label: "Workout",
        // });
        
        
        

        // if (moment(this.date).day() === 0) {
        //     tasks.push({
        //         label: "Church",
        //     });
        // }
        //
        // if (moment(this.date).day() === 2) {
        //     tasks.push({
        //         label: "Trash & Recycling",
        //     });
        // }
        //
        // if (moment(this.date).day() === 5) {
        //     tasks.push({
        //         label: "Trash",
        //     });
        // }
        //
        // if (moment(this.date).day() > 0 && moment(this.date).day() < 5) {
        //     tasks.push({
        //         label: "GG Hour",
        //     });
        // }
        //
        // if (moment(this.date).day() === 5) {
        //     tasks.push({
        //         label: "GG Drop Off",
        //     });
        // }

        if (moment(this.date).day() === 3) {
            tasks.push({
                label: "Leadership Training",
            });
        }

        for (let i = 0; tasks.length < 27 - 3 - this.shelbyBusinessTasks.length; i++)
        {
            tasks.push({
                label: "",
            });
        }


        return tasks;
    }
    
}