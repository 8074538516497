import moment from "moment";

export default class PettiDay {
    constructor(date) {
        this.date = date;
        this.day = date.format("dddd");
        this.isWorkDay = moment(this.date).day() === 0 || moment(this.date).day() === 6;
        this.displayDate = date.format("MMMM D, yyyy");
        this.morningTasks = this.getMorningTasks();
        this.getAfterItTasks = this.getGetAfterItTasks();
        this.dayTasks = this.getDayTasks();
        this.workWrapUpTasks = this.getWorkWrapUpTasks();
        this.nightTasks = this.getNightTasks();
    }
    
    getMorningTasks() {
        let tasks = [];
        tasks.push({
            label: "Take AG",
        });
        tasks.push({
            label: "Make Bottles",
        });
        tasks.push({
            label: "Write 30 minutes",
        });

        if (moment(this.date).day() === 0) {
            tasks.push({
                label: "Church",
            });
        }
        
        if (moment(this.date).day() === 2) {
            tasks.push({
                label: "Trash & Recycling",
            });
        }

        if (moment(this.date).day() === 5) {
            tasks.push({
                label: "Trash",
            });
        }

        if (moment(this.date).day() > 0 && moment(this.date).day() < 5) {
            tasks.push({
                label: "GG Hour",
            });
        }

        if (moment(this.date).day() === 5) {
            tasks.push({
                label: "GG Drop Off",
            });
        }
        
        


        return tasks;
    }
    
    getGetAfterItTasks() {
        let tasks = [];

        if (moment(this.date).day() === 1) {
            tasks.push({
                label: "Prep Team Slides",
            });
            tasks.push({
                label: "Morning Meetings",
            });
        }

        // Sunday
        if (moment(this.date).day() === 0) {
            tasks.push({
                label: "Make Chicken & Rice",
            });
            tasks.push({
                label: "Sam's Run",
            });
            tasks.push({
                label: "Finalize Petti Thought",
            });
            tasks.push({
                label: "Set GG Food for Week",
            });
        }

        if (moment(this.date).day() === 4) {
            tasks.push({
                label: "Prep Leadership Slides",
            });
            tasks.push({
                label: "Leadership Team Meeting",
            });
        }
        
        for (let i = 0; tasks.length < 27; i++)
        {
            tasks.push({
                label: "",
            });
        }
        return tasks;
        
    }
    
    getDayTasks() {
        let tasks = [];
        tasks.push({
            label: "Water □ 1 □ 2 □ 3 □ 4",
        });
        tasks.push({
            label: "Food □ B □ L □ D",
        });
        tasks.push({
            label: "Workout",
        });
        
        // Sunday

        return tasks;
    };

    getWorkWrapUpTasks() {
        let tasks = [];
        tasks.push({
            label: "Email 0",
        });
        tasks.push({
            label: "Tasks 0",
        });
        
        tasks.push({
            label: "Clean Office",
        });
        return tasks;
    }

    getNightTasks() {
        let tasks = [];
        tasks.push({
            label: "Make Tomorrow Task List",
        });
        tasks.push({
            label: "Cold Tub",
        });
        tasks.push({
            label: "Family Time",
        });
        tasks.push({
            label: "Clean Bottles",
        });
        tasks.push({
            label: "Set Tomorrow Gear",
        });
        
        if (moment(this.date).day() === 1) {
            tasks.push({
                label: "Prep Recycling",
            });
        }
        return tasks;
    }
}