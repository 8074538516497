<template>
	<v-btn 
		v-bind="$props"
		@click="$emit('click')"
		:color="color"
		small
		class="button"
	>
		<slot></slot>
	</v-btn>
</template>

<script>
export default {
	name: "AppBtn",
	props: {
		color: {
			default: "primary"
		},
		outlined: {
			type: Boolean,
			default: false
		},
		
	}
}
</script>


<style scoped>
.button {
	text-transform: none !important;
	font-weight: 500;
	letter-spacing: normal;
}
</style>