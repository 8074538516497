<template>
	<AppContainer>
<!--		<AppRow>-->
<!--			<div>-->
<!--				<BuilderPageTitle>{{title}}</BuilderPageTitle>-->
<!--				<div class="text-subtitle-1 mt-2">{{message}}</div>-->
<!--			</div>-->
<!--			<v-spacer></v-spacer>-->
<!--			<AppBtn @click="$emit('previous')" outlined class="mr-2">Previous</AppBtn>-->
<!--			<AppBtn @click="$emit('next')" >Next</AppBtn>-->
			
<!--		</AppRow>-->

<!--			<v-divider class="mt-4 mb-4"></v-divider>-->
		
		<slot></slot>
	</AppContainer>
</template>

<script>
import AppContainer from "@/components/AppContainer.vue";
import BuilderPageTitle from "@/components/Builder/BuilderPageTitle.vue";
import AppDivider from "@/components/AppDivider.vue";
import AppRow from "@/components/AppRow.vue";
import AppBtn from "@/components/AppBtn.vue";
import BookDrawer from "@/components/Builder/BookDrawer/index.vue";

export default {
	name: "BuilderLayout",
	components: {BookDrawer, AppBtn, AppRow, AppDivider, BuilderPageTitle, AppContainer},
	props: {
		title: {
			type: String,
			default: "Builder"
		},
		message: {
			type: String,
			default: "Subtitle"
		}
	}
}
</script>


<style scoped>

</style>