import moment from "moment/moment";

export default class Task {
    constructor(task) {
        this.label = task?.label ? task.label : null;
        this.frequencyType = task?.frequencyType ? task.frequencyType : null;
        this.on = task?.on ? task.on : null;
        this.from = task?.from ? task.from : null;
        this.to = task?.to ? task.to : null;
        this.premadeTaskId = task?.premadeTaskId ? task.premadeTaskId : null;
        this.taskCategoryIds = task?.taskCategoryIds ? task.taskCategoryIds : null;
        this.count = 0
    }
    
    getTaskDescription() {
        const {
            on
        } = this
        switch (this.frequencyType) {
            case 1:
                return 'Every day.'
            case 2:
                return `Every weekday (Mon - Fri).`
            case 3:
                // TODO: Fix this
                return `Once on ${on}`
            case 4:
                // TODO: Fix this
                return `Every ${on} days.`
            case 5:
                return !!on ? `First ${moment().day(on).format('dddd')} of the month.` : 'Error 5'
            case 6:
                return !!on ? `Second ${moment().day(on).format('dddd')} of the month.` : 'Error 6'
            case 7:
                return !!on ? `Third ${moment().day(on).format('dddd')} of the month.` : 'Error 7'
            case 8:
                return !!on ? `Fourth ${moment().day(on).format('dddd')} of the month.` : 'Error 8'
            case 9:
                return !!on ? `Last ${moment().day(on).format('dddd')} of the month.` : 'Error 9'
            case 10:
                return 'Last day of the month.'
            default:
                return "Error"
        }
    }
    
}