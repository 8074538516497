<template>
	<div>
		<v-menu
			ref="menu"
			v-model="datePickerMenu"
			:close-on-content-click="false"
			:return-value.sync="value"
			transition="scale-transition"
			offset-y
			min-width="auto"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-text-field
					:value="value"
					:label="label"
					readonly
					v-bind="attrs"
					v-on="on"
					outlined
					dense
					hide-details
					:placeholder="placeholder"
				>
					<template v-slot:append v-if="value" >
						<div 
							@click="clearDate"
							style="display: flex; height: 24px; justify-content: center; align-items: center" 
							class="cursor-pointer"
						>
							<v-icon small>mdi-close</v-icon>
						</div>
						
					</template>
				</v-text-field>
			</template>
			<v-date-picker
				:value="value"
				@input="e => $emit('input', e)"
				no-title
				scrollable
				:max="max"
				:min="min"
			>
				<v-spacer></v-spacer>
				<v-btn
					text
					color="primary"
					@click="datePickerMenu = false"
				>
					Cancel
				</v-btn>
				<v-btn
					text
					color="primary"
					@click="$refs.menu.save(value)"
				>
					OK
				</v-btn>
			</v-date-picker>
		</v-menu>
	</div>
</template>

<script >
export default {
	name: 'AppDatePicker',
	props: {
		value: {
			required: false
		},
		label: {},
		placeholder: {
			type: String,
			default: ''
		
		},
		max: {
			default: null
		},
		min: {
			default: null
		}
	},
	data() {
		return {
			datePickerMenu: false
		}
	},
	methods: {
		openDatePicker() {
			this.datePickerMenu = true
		},
		// onChange(e) {
		// 	this.$emit('input', e)
		// },
		clearDate() {
			this.$refs.menu.save([])
			this.$emit('input', null)
		}
	}
}
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
	border-color: #eeeeee;
}
</style>