<template>
	<v-card @click="$emit('click')">
		<slot></slot>
	</v-card>
</template>

<script>
export default {
	name: "AppCard"
}
</script>


<style scoped>

</style>