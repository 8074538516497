<template>
	<div>
		<slot></slot>
	</div>
  
</template>

<script >
export default {
	name: "AppInput",
	props: {
		value: {

		},
		label: {

			type: String
		},
		placeholder: {
			type: String,
		},
		disabled: {
			type: Boolean
		},
		errorLabel: {
			
		},
		rules: {
			
		},
		row: {
			type: Boolean
		},
		helperMessage: {},
		name: {
			type: String
		},
	},
}
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
	border-color: #eeeeee;
}
</style>