<template>
	<div  style="width: 100%;display: flex; flex-direction: column;">

		<div v-for="(task, index) in tasks" :key="index">
			<div>
				<Task :task="task"></Task>
			</div>
			
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import Task from "@/components/Task.vue";
export default {
	name: 'TaskList',
	components: {
		Task
	},
	props: {
		tasks: {
			type: Array,
			required: true
		}
	}
}
</script>

<style>

.tasks {
	width: 100%;

	
	display: flex; 
	flex-direction: column;
}
</style>

