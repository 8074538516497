<template>
	<AppContainer>

		<AppRow>
			<AppCol 
				cols="4" 
				v-for="page in taskPageLayouts" 
				:key="page.id" 
				@click="() => selectPage(page)"
				style="display: flex; justify-content: center"
				
			>
				<AppCard style="width: 3.4in; height: 4.2in; padding: 0; overflow: hidden" :class="{'selected-page': page.id === TASK_PAGE_LAYOUT.id}">
					<div
						:is="page.component"
						:day="TASKBOOK_DAYS[0]"
						:taskBook="TASKBOOK"
						style="border: 1px solid #ddd;  transform: scale(.4); transform-origin: top left;"
						class="select-page-layout"
						
					></div>
				</AppCard>
			</AppCol>
			

		</AppRow>
	</AppContainer>
</template>

<script>
import AppContainer from "@/components/AppContainer.vue";
import AppRow from "@/components/AppRow.vue";
import AppCol from "@/components/AppCol.vue";
import TaskPage1 from "@/components/TaskBookDocument/TaskPages/TaskPage1.vue";
import TaskPage2 from "@/components/TaskBookDocument/TaskPages/TaskPage2.vue";
import {mapGetters} from "vuex";
import {TASKBOOK, TASKBOOK_DAYS, TASK_PAGE_LAYOUT} from "@/store/getters";
import {taskPageLayouts} from "@/js/constants";
import {SET_PAGE_LAYOUT} from "@/store/actions";
import AppCard from "@/components/AppCard.vue";

export default {
	name: "TaskPageLayoutSelector",
	components: {AppCard, AppCol, AppRow, AppContainer, TaskPage1, TaskPage2},
	data() {
		return {
			taskPageLayouts
		}	
	},
	computed: {
		...mapGetters([
			TASKBOOK,
			TASKBOOK_DAYS,
			TASK_PAGE_LAYOUT
		]),
	},
	methods: {
		selectPage(page) {
			this.$store.dispatch(SET_PAGE_LAYOUT, page)
		}	
	}
}
</script>


<style scoped>
.select-page-layout:hover {
	cursor: pointer;
}

.selected-page {
	border: 1px solid var(--v-primary-base);
}
</style>