<template>
  <div class="def-logo" @click="$emit('click')">
	  <svg  height="100%" viewBox="0 0 379 221" fill="none" xmlns="http://www.w3.org/2000/svg">
		  <rect x="9.5" y="9.5" width="360" height="202" :stroke="color" stroke-width="19"/>
		  <line x1="9.55407" y1="8.59924" x2="145.554" y2="211.599" :stroke="color" stroke-width="23"/>
		  <line y1="-11.5" x2="238.539" y2="-11.5" transform="matrix(-0.552484 0.833523 0.833523 0.552484 154.789 15)" :stroke="color" stroke-width="23"/>
	  </svg>
  
  </div>
</template>

<script>
export default {
    name: "DefLogo",
	props: {
		color: {
			type: String,
			default: 'black'
		}
	}
	
}
</script>



<style scoped>

</style>