<template>
	<v-tabs
		:class="{'tabs': true, 'mb-4': marginBottom, 'border-bottom': underline, 'border-right': vertical,}"
		:hide-slider="hideSlider"
		:slider-size="3"
		:slider-color="color"
		:value="value"
		:vertical="vertical"
		:centered="centered"
		@change="onChange"
	>
		<slot/>
	</v-tabs>
</template>

<script>
export default {
	name: "AppTabs",
	props: {
		value: {
			type: Number
		},
		vertical: {
			type: Boolean
		},
		centered: {
			type: Boolean
		},
		color: {
			default: "primary"
		},
		underline: {
			type: Boolean,
			default: true
		},
		marginBottom: {
			type: Boolean,
			default: true
		}
		
	},
	data() {
		return {
			hideSlider: false
		}
	},
	methods: {
		onChange(value) {
			this.$emit('input', value)
			
		}
	}
}
</script>

<style scoped >
.tabs {
	background-color: transparent;
}

.border-bottom {
	border-bottom: 1px;
	border-bottom-style: solid;
	border-bottom-color: var(--v-border-base);
}

.border-right {
	border-right: 1px solid var(--v-light-darken1);
	height: 100%
}


</style>