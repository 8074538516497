<template>
  <v-app>
<!--	  <NavBar />-->


    <v-main>
      <router-view v-if="windowWidth > 1023" />
	    <div v-else style="height: 100vh; width: 100vw; display: flex; flex-direction: column; justify-content: center; align-items: center">
		    <AppLogo height="50px" />
		    <div class="mt-4">
			    Tasko Builder requires a minimum resolution of 1024px
		    </div>
		    <div>
			    Please widen your browser window. 
		    </div>
		    
	    </div>
    </v-main>
  </v-app>
</template>

<script>

import NavBar from "@/components/NavBar.vue";
import AppLogo from "@/components/AppLogo.vue";

export default {
  name: 'App',
	components: {AppLogo, NavBar},

  data() {
	  return {
		  windowWidth: window.innerWidth
	  }
  },
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		})
		
		document.title = "Tasko"
	},
	
	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},
	
	methods: {
		onResize() {
			this.windowWidth = window.innerWidth
		}
	}
};
</script>

<style>
@import './styles/main.css';
@import url('https://fonts.cdnfonts.com/css/sf-pro-display');

.v-application {
	font-size: 16px
}

.print-page {
	margin: 0px;
	padding: .25in;
	width: 8in; /* width: 7in; */
	height: 10.5in; /* or height: 9.5in; */
	clear: both;
	background-color: none;
	//page-break-after: always;
	page-break-before: always;
	//border: 1px solid #eeeeee;
}



.section {
	border: 1px solid #fff;
	padding-bottom: 20px
	
}

.section-title {
	color: grey;
	text-transform: uppercase;
	text-align: left;
	border-bottom: 1px solid #ddd;
	height: 24px;
	width: 100%;
	font-weight: 300;
	letter-spacing: .05em
}
.col {
	height: 100%;
}

.col-1 {
	flex: 1;
	padding-right: 30px;
}
.col-2 {
	flex: 2
}

.morning {
	
}

.footer {
	color: grey;
	font-style: italic;
	text-align: center;
	font-weight: lighter;
}

#app {
	font-family: 'SF Pro Display',Arial, sans-serif;
}

</style>
