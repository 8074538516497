<template>
	<div class="task" style="flex: 1;
	border-bottom: 1px dashed #ddd;
	display: flex;
	align-items: center;
	padding: 5px;
	height: 30px;
	font-size: 18px;">
		<div class="checkbox" style="height: 14px; 
	width: 14px;
	border: 2px solid #ddd;
	margin-right: 7px;
	border-radius: 3px"></div>
		<div>{{ task.label }}</div>
	</div>
</template>

<script>
// @ is an alias to /src

export default {
	name: 'Task',
	props: {
		task: {
			type: Object,
			required: true
		}
	}
}
</script>

<style>
.checkbox {
	height: 14px; 
	width: 14px;
	border: 2px solid #ddd;
	margin-right: 7px;
	border-radius: 3px
	
}
.task {
	flex: 1;
	border-bottom: 1px dashed #ddd;
	display: flex;
	align-items: center;
	padding: 5px;
	height: 30px;
	font-size: 18px;
	
	
	
}
</style>

