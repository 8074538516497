<template>
	
		<v-dialog
			v-model="value"
			v-bind="$props"
			@click="$emit('click')"
			@click:outside="closeDialog"
			:color="color"
			:width="width"
		>
			<v-card class="pa-4">
				<div style="display: flex; justify-content: flex-end">
					<v-icon icon="" @click="closeDialog">mdi-close</v-icon>
				</div>
				
			
			<slot></slot>
			</v-card>
		</v-dialog>
	
</template>

<script>
export default {
	name: "AppDialog",
	props: {
		value: {
			required: true,
		},
		color: {
			default: "secondary"
		},
		width: {
			type: Number,
			default: 500
		
		}
	},
	methods: {
		
		closeDialog() {
			this.$emit('close')
		}
	},
}
</script>


<style scoped>

</style>