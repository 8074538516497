<template>
			<router-view></router-view>

</template>

<script>
  import HelloWorld from '../components/HelloWorld'
  import AppContainer from "@/components/AppContainer.vue";
  import AppBtn from "@/components/AppBtn.vue";
  import LogoLightBlue from '@/assets/logo-light-blue.svg'
  import LogoDarkRed from '@/assets/logo-dark-red.svg'
  import DefThemeToggle from "@/components/DefThemeToggle.vue";
  import {mapGetters} from "vuex";
  import {CURRENT_LOGO} from "@/store/getters";

  export default {
    name: 'Home',

    components: {
	    DefThemeToggle,
	    AppBtn,
	    AppContainer,
      HelloWorld,
    },
	  mounted() {
		  // console.log(this.$route)
		if (this.$route.name === 'jocko') {
			this.$vuetify.theme.dark = true;
			this.$store.dispatch('SET_LOGO', LogoDarkRed)
		}
		
		  // this.$store.dispatch('SET_LOGO', LogoLightBlue)
	  },
	  data() {
		  return {
			  logo: LogoLightBlue
		  }
	  },
	  computed: {
		...mapGetters([
			CURRENT_LOGO
		])
	  }
  }
</script>

<style scoped>
.home-hero {
	//background-image: url("https://cdn.discordapp.com/attachments/1191921187320766644/1194450556085870673/Task-book-mockup.jpg?ex=65b065c4&is=659df0c4&hm=fea92715f125472dbc78a3c502ef12c1a2a7dc2f86fcd9f9d182da09af8f140c&");
	background-position: center;background-size: cover;
}
</style>
