<template>
	<div class="task-page-header">
		<div class="task-page-header-day">{{day.day}}</div>
		<div class="task-page-header-date">{{day.displayDate}}</div>
	</div>
</template>

<script>
export default {
	name: "TaskPageHeader2",
	props: {
		day: {
			required: true
		},
	
	}
}
</script>


<style scoped>

</style>