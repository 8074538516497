<template>
<!--	<div>-->
<!--		<div class="print-page" v-for="(day, index) in days" :key="index" >-->
<!--			<div class="header">-->
<!--				<div class="day">{{day.day}}</div>-->
<!--				<div class="date">{{day.displayDate}}</div>-->
<!--				<div class="date" v-if="day.businessDayNumber">Business Day: {{day.businessDayNumber}}</div>-->
<!--			</div>-->
<!--		<div class="col col-2">-->
<!--			<div class="section morning" v-if="day.shelbyBusinessTasks.length > 0">-->
<!--				-->
<!--				<div class="section-title">MERIT standard</div>-->
<!--				<TaskList :tasks="day.shelbyBusinessTasks"></TaskList>-->
<!--			</div>-->
<!--			<div class="section morning">-->
<!--				-->
<!--				<div class="section-title">Tasks</div>-->
<!--				<TaskList :tasks="day.tasks"></TaskList>-->
<!--			</div>-->
<!--			-->
<!--		</div>-->
<!--		</div>-->
<!--	</div>-->
	<div>
		<PrintPage class="print-page" v-for="(day, index) in days" :key="index" >
			<!--			<div class="header">-->
			<!--				<div class="day">{{day.day}}</div>-->
			<!--				<div class="date">{{day.displayDate}}</div>-->
			<!--			</div>-->
			<TaskPageHeader3 :day="day"></TaskPageHeader3>
			<v-container fluid style="padding: 0">
				<div style="display: flex; height: 100%">
					<div class="col col-5" style="display: flex; flex-direction: column; height: 8in">
						<div class="section morning">
							
							<div class="section-title">Morning</div>
							<div>
								<TaskList :tasks="day.morningTasks"></TaskList>
							</div>
						</div>
						<div class="section morning">
							
							<div class="section-title">Day</div>
							<TaskList :tasks="day.dayTasks"></TaskList>
						</div>
						<div style="flex: 1">
<!--							<div class="message">-->
<!--								<div class="section-title">Be Better</div>-->
<!--								<ol style="padding-left: 20px; font-size: 18px">-->
<!--									<li>Shut your pie hole.</li>-->
<!--									<li>Talk last.</li>-->
<!--									<li>Ask questions that make people smarter and more specific.</li>-->
<!--								</ol>-->
<!--							</div>-->
						</div>
<!--						<div class="section morning" v-if="!day.isWorkDay" >-->
<!--							-->
<!--							<div class="section-title" >WORK WRAP UP</div>-->
<!--							<TaskList :tasks="day.workWrapUpTasks"></TaskList>-->
<!--						</div>-->
						<div class="section morning">
							
							<div class="section-title">NIGHT</div>
							<TaskList :tasks="day.nightTasks"></TaskList>
						</div>
					</div>
					<div class="col col-7">
						<div class="section morning">
							
							<div class="section-title">LIST</div>
							<TaskList :tasks="day.shelbyBusinessTasks"></TaskList>
							<TaskList :tasks="day.tasks"></TaskList>
							
							<!--			<div class="section morning" v-if="day.shelbyBusinessTasks.length > 0">-->
							<!--				-->
							<!--				<div class="section-title">MERIT standard</div>-->
							<!--				<TaskList :tasks="day.shelbyBusinessTasks"></TaskList>-->
							<!--			</div>-->
						</div>
					</div>
				</div>
			</v-container>
			
			<div class="footer">Get After It</div>
		</PrintPage>
	</div>
</template>

<script>
// @ is an alias to /src

import TaskList from "@/components/TaskList.vue";
import moment from "moment";
import ShelbyDay from "@/js/ShelbyDay";
import {shelbyHolidays} from "@/js/constants";
import ShelbyDayV2 from "@/js/ShelbyDayV2";
import TaskPageHeader1 from "@/components/TaskBookDocument/TaskPageHeaders/TaskPageHeader1.vue";
import PrintPage from "@/components/PrintPage.vue";
import TaskPageHeader3 from "@/components/TaskBookDocument/TaskPageHeaders/TaskPageHeader3.vue";
export default {
	name: 'ShelbyPlanner',
	components: {TaskPageHeader3, PrintPage, TaskPageHeader1, TaskList},
	data() {
		return {
			startDate: moment("2024-02-01"),
			days: [],
			tasks: [],
		}
	},
	mounted() {
		this.init()
	},
	methods: {
		init() {
			this.createDays();
		},
		createDays() {
			let days = [];
			let date = this.startDate;
			let businessDayCount = 1;
			for (let i = 0; i < date.daysInMonth(); i++) {
				let isBusinessDay = true;
				if (moment(date).day() === 0 || moment(date).day() === 6) isBusinessDay = false;
				shelbyHolidays.forEach(holiday => {
					if (moment(holiday.date).isSame(date, 'day')) isBusinessDay = false
				})
				
				
				let businessDayNumber = isBusinessDay ? businessDayCount : null
				days.push(new ShelbyDayV2(date, businessDayNumber));
				date.add(1, "day");
				if (isBusinessDay) businessDayCount++;
			}
			this.days = days;
		},
		
	}

}
</script>
