const RESET_TASK_BOOK = 'RESET_TASK_BOOK';
const UPDATE_TASK = 'UPDATE_TASK';
const ADD_NEW_TASK = 'ADD_NEW_TASK';
const DELETE_TASK = 'DELETE_TASK';

const SET_PAGE_LAYOUT = 'SET_PAGE_LAYOUT';
const SET_LOGO = 'SET_LOGO';

export {
    RESET_TASK_BOOK,
    UPDATE_TASK,
    ADD_NEW_TASK,
    DELETE_TASK,
    SET_PAGE_LAYOUT,
    SET_LOGO
}